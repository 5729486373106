import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Switch,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import queryString from 'query-string';

import {
  closeSaveQuoteModalConfirmation,
  loadMultiModalData,
  saveSelectedAddPetModal,
  saveSelectedEditPetModal,
  validateEmployerId,
} from '../../../actions';

import figoLogo from '../../../assets/figo-logo.svg';
import figoLogoSmall from '../../../assets/figo-logo-round.svg';

import { YourInfoContent } from '../yourInfo/YourInfoContent';
import { MODAL_RELATION_LIST, QuotingSteps } from '../../../constants';
import LiveVetInfoScreen from '../../liveVetInfo/LiveVetInfoScreen';
import LiveVetContextRoot from '../../contexts/LiveVetContext';
import ModalContainer from '../../common/ModalContainer';
import { ModalAddPet } from '../../common/modalAddPet/ModalAddPet';
import ModalRemovePet from '../ModalRemovePet';
import MultiModalInfo from '../../common/MultiModalInfo';
import { SaveYourQuoteModal } from '../SaveYourQuoteModal';
import SidebarSaveQuoteModalConfirmation
from '../SidebarSaveQuoteModalConfirmation';
import { HeaderIAIC } from './HeaderIAIC';
import { StickyHeader } from './StickyHeader';
import { QuotingStepsComponent } from './QuotingStepsComponent';
import { MyQuote } from './MyQuote';
import { PriceStickyBar } from '../PriceStickyBar';
import { AnimatedFloatingHeader } from './AnimatedFloatingHeader';
import { QuoteContent } from '../QuoteContent';
import { FigoLoadingOverlay } from '../../common/FigoLoadingOverlay';
import {
  createEffectiveDate,
  forceFocusElement,
  formatParameters,
  isPaycheck,
} from '../../../util';
import { URL_LIVE_VET_YOUR_INFO, URL_ROOT_QUOTE } from '../../../routes';
import { DuplicatedPurchasesModal } from '../purchase/duplicatedPurchasesModal';

import './css/QuotingHeader.css';

const {
  NEED_TO_KNOW_MODAL_ID,
  PET_CO_PAY_MODAL_ID,
  TERMS_AND_COND_MODAL_ID,
  WELLNESS_MODAL_ID,
} = MODAL_RELATION_LIST;

function QuotingHeader() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const parametersRaw = queryString.parse(location.search);
  const parameters = formatParameters(parametersRaw);
  const store = useSelector(({ quoting }) => quoting);
  const [figoSmallLogoVisible, setFigoSmallLogoVisible] = useState(false);
  const [myQuoteFlatCorner, setMyQuoteFlatCorner] = useState(false);
  const {
    addPetModalVisible,
    currentQuoteStep,
    customerZipCode,
    editPetModalVisible,
    employerIdValidated,
    isLoading,
    isMonthlyPlan,
    multiModalData,
    liveVetPrice,
    multiModalUserSelection,
    quoteSelectedValues,
    removePetModalVisible,
    saveQuote,
    searchParameters,
  } = store;

  const {
    needToKnowIdSelected,
    needToKnowModalVisible,
    petCoPayIdSelected,
    petCoPayModalVisible,
    termsAndCondIdSelected,
    termsAndCondModalVisible,
    wellnessIdSelected,
    wellnessModalVisible,
  } = multiModalUserSelection || {};
  const { savedQuoteModalVisible } = saveQuote || {};

  const isLiveVet = location.pathname === URL_LIVE_VET_YOUR_INFO;

  const styles = useMemo(() => {
    const isSecondStep = currentQuoteStep === QuotingSteps.YourInfo.value;
    if (Object.entries(parametersRaw).length || isSecondStep) {
      return {
        bodyStyle: 'App-body-quote',
      };
    }

    return {
      bodyStyle: '',
    };
  }, [currentQuoteStep, parametersRaw]);

  const showLoadingOverlay = useMemo(() => isLoading
    || store.isLoadingNewAddress
    || store.purchase.loading
    || store.rateSummary.loading
    || store.sessionInformation.isLoading, [
    isLoading,
    store.isLoadingNewAddress,
    store.purchase.loading,
    store.rateSummary.loading,
    store.sessionInformation.isLoading,
  ]);

  const handleScroll = () => {
    if (document.body) {
      setFigoSmallLogoVisible(
        document.body.getBoundingClientRect().top <= -210,
      );
      setMyQuoteFlatCorner(
        document.body.getBoundingClientRect().top <= -140,
      );
    }
  };

  useEffect(() => {
    if (parameters.empGuid && !employerIdValidated) {
      dispatch(validateEmployerId(parameters.empGuid));
    }
  }, [employerIdValidated, parameters.empGuid, dispatch]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const handleCloseModalAddPet = useCallback(() => {
    dispatch(saveSelectedEditPetModal(false));
    dispatch(saveSelectedAddPetModal(false));
    const { petQuoteSelected, data: { ebPetQuoteResponseList } } = store;
    const currentQuote = (ebPetQuoteResponseList || [])
      .find((quote) => quote.petQuoteId === petQuoteSelected);
    forceFocusElement(`petQuoteDropDownItem${currentQuote.petQuoteId}-header`);
  }, [dispatch, store]);

  const handleOnClickCloseConfirmationModal = useCallback(() => {
    dispatch(closeSaveQuoteModalConfirmation());
  }, [dispatch]);

  useEffect(() => {
    if (!customerZipCode || customerZipCode === multiModalData?.ZipCode) {
      return;
    }

    dispatch(loadMultiModalData({
      effectiveDate: createEffectiveDate(),
      zipCode: customerZipCode,
    }));
  }, [customerZipCode, dispatch, multiModalData]);

  const isSecondStep = currentQuoteStep === QuotingSteps.YourInfo.value;

  const headerIAICClassName = useMemo(() => {
    if (currentQuoteStep === QuotingSteps.SelectPlan.value) {
      return '';
    }

    return 'Quoting-header-your-info';
  }, [currentQuoteStep]);

  function renderHeaderContent() {
    const isRootQuote = location.pathname.toLocaleLowerCase()
      === URL_ROOT_QUOTE;

    if (isRootQuote) {
      return <AnimatedFloatingHeader isSecondStep={isSecondStep} />;
    }

    return (
      <header
        aria-label="Site header"
        className={'Quoting-header-container'
          + ` ${isLiveVet ? 'Quoting-header-container-big' : ''}`}
        role="banner"
      >
        <HeaderIAIC
          classContainer={headerIAICClassName}
        />

        <div className="Quoting-header-container-row-top">
          <div className="Quoting-header-logo-image-container">
            <img
              alt="Figo Logo"
              className={`Quoting-header-logo-image
              ${figoSmallLogoVisible && 'Quoting-header-gone'}`}
              src={figoLogo}
            />
          </div>

          <QuotingStepsComponent
            currentQuoteStep={currentQuoteStep}
            figoSmallLogoVisible={figoSmallLogoVisible}
            isPaycheck={isPaycheck}
            searchParameters={searchParameters}
          />
        </div>

        <div className="Quoting-header-container-row-bottom">
          <img
            alt=""
            className={`Quoting-header-logo-image-small
            ${!figoSmallLogoVisible && 'Quoting-header-gone'}`}
            src={figoLogoSmall}
          />

          <MyQuote
            isMonthlyPlan={isMonthlyPlan}
            isPaycheck={isPaycheck}
            liveVetPrice={liveVetPrice}
            myQuoteFlatCorner={myQuoteFlatCorner}
            quoteSelectedValues={quoteSelectedValues}
            showQuotePriceMobile={isLiveVet}
          />

          {store.data && (
            <StickyHeader
              isSecondStep={isSecondStep}
              petQuoteResponseList={store.data.ebPetQuoteResponseList}
            />
          )}
        </div>
      </header>
    );
  }

  return (
    <>
      <HeaderIAIC
        classContainer={isSecondStep ? 'Gone' : headerIAICClassName}
      />

      {renderHeaderContent()}

      <main className={`App-body ${styles.bodyStyle}`}>
        <Switch>
          <Route exact path={match.path}>
            <QuoteContent />
          </Route>

          <Route path={`${match.path}/yourInfo/:scrollToTop?`}>
            <YourInfoContent showLoadingOverlay={showLoadingOverlay} />
          </Route>

          <Route path={`${match.path}/liveVetInfo/:scrollToTop?`}>
            <LiveVetContextRoot.Provider>
              <LiveVetInfoScreen />
            </LiveVetContextRoot.Provider>
          </Route>
        </Switch>
      </main>

      <PriceStickyBar />

      <DuplicatedPurchasesModal />

      <ModalContainer
        cancelable={false}
        show={addPetModalVisible || editPetModalVisible}
      >
        <ModalAddPet
          handleCloseModalAddPet={handleCloseModalAddPet}
          isEditing={editPetModalVisible}
          show={addPetModalVisible || editPetModalVisible}
        />
      </ModalContainer>

      <ModalContainer
        cancelable={false}
        show={removePetModalVisible}
      >
        <ModalRemovePet />
      </ModalContainer>

      <MultiModalInfo
        menuSelected={needToKnowIdSelected}
        modalId={NEED_TO_KNOW_MODAL_ID}
        show={needToKnowModalVisible}
      />

      <MultiModalInfo
        menuSelected={termsAndCondIdSelected}
        modalId={TERMS_AND_COND_MODAL_ID}
        show={termsAndCondModalVisible}
      />

      <MultiModalInfo
        menuSelected={wellnessIdSelected}
        modalId={WELLNESS_MODAL_ID}
        show={wellnessModalVisible}
      />

      {/* FPA-11163 */}
      <MultiModalInfo
        menuSelected={petCoPayIdSelected}
        modalId={PET_CO_PAY_MODAL_ID}
        show={petCoPayModalVisible}
      />

      <MultiModalInfo
        menuSelected={-1}
        show={store.multiModalCustomData}
      />

      <SaveYourQuoteModal />

      <SidebarSaveQuoteModalConfirmation
        handleOnClick={handleOnClickCloseConfirmationModal}
        show={savedQuoteModalVisible}
      />

      <FigoLoadingOverlay visible={showLoadingOverlay} />

      {/* OneInc modal create here */}
      <div id="portalOneContainer" />
    </>
  );
}

export { QuotingHeader };
