import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { msalConfig } from '../authConfigB2C';
import {
  logError,
  onLoginB2CROPC,
  onLoginB2CROPCFailed,
  onLoginDiamondUser,
} from '../actions';
import { acquireTokenByPassword, delay } from '../util';

const USERNAME_OR_PASSWORD_ERROR = 'AADB2C90225';

const useB2CInfo = () => {
  const { accounts, inProgress, instance } = useMsal();
  const [b2cToken, setB2cToken] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const [ropcError, setRopcError] = useState('');
  const { t } = useTranslation('login');

  const loginB2CROPC = useCallback(async ({ username, password }) => {
    dispatch(onLoginB2CROPC());
    const response = await acquireTokenByPassword({ password, username });
    if (response.error) {
      dispatch(onLoginB2CROPCFailed());
      if (response.error_description.includes(USERNAME_OR_PASSWORD_ERROR)) {
        setRopcError(t('invalidUsernamePassword'));
        return;
      }
      dispatch(logError({
        action: 'loginB2CROPC',
        description: response.error_description,
        endpoint: '',
        statusCode: '',
      }));
      setRopcError(t('unableToAccess'));
    } else {
      dispatch(onLoginDiamondUser({
        b2cToken: response.access_token || response.id_token,
        email: username,
      }));
    }
  }, [dispatch, t]);

  const loginB2CSilent = useCallback(({
    existingEmail = '',
  }) => {
    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      const tokenRequest = {
        account: accounts[0],
        loginHint: existingEmail,
        prompt: 'login',
      };

      instance.acquireTokenSilent(tokenRequest).then((tokenResponse) => {
        setB2cToken(tokenResponse.accessToken
          || tokenResponse.idToken);
        instance.setActiveAccount(tokenResponse.account);

        dispatch(onLoginDiamondUser({
          b2cToken: tokenResponse.accessToken
            || tokenResponse.idToken,
          email: existingEmail,
        }));
      }).catch(async (e) => {
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(tokenRequest);
        }

        throw e;
      });
    }
  }, [inProgress, accounts, instance, dispatch]);

  const loginB2C = useCallback(({
    continueWithoutLogin = () => { },
    existingEmail = '',
    urlState = '',
  }) => {
    const tokenRequest = {
      account: accounts[0],
      loginHint: existingEmail,
      prompt: 'login',
      state: urlState,
    };

    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenPopup(tokenRequest)
        .then(async (tokenResponse) => {
          setB2cToken(tokenResponse.accessToken
            || tokenResponse.idToken);
          instance.setActiveAccount(tokenResponse.account);

          dispatch(onLoginDiamondUser({
            b2cToken: tokenResponse.accessToken
              || tokenResponse.idToken,
            email: existingEmail,
          }));

          await delay(3000);
          history.push(urlState);
        })
        .catch((error) => {
          // On Close Popup
          if (error instanceof BrowserAuthError) {
            if (continueWithoutLogin) {
              continueWithoutLogin();
            }
          }

          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(tokenRequest)
              .then(async (tokenResponse) => {
                setB2cToken(tokenResponse.accessToken
                  || tokenResponse.idToken);
                instance.setActiveAccount(tokenResponse.account);

                dispatch(onLoginDiamondUser({
                  b2cToken: tokenResponse.accessToken
                    || tokenResponse.idToken,
                  email: existingEmail,
                }));
                await delay(3000);
                history.push(urlState);
              });
          }
        });
    }
  }, [accounts, dispatch, history, inProgress, instance]);

  const logoutB2C = useCallback(({ logoutUrl = '' }) => {
    const logoutRequest = {
      account: instance.getActiveAccount(),
      authority: msalConfig.auth.authority,
      postLogoutRedirectUri: logoutUrl || msalConfig.auth.postLogoutRedirectUri,
    };

    instance.logoutRedirect(logoutRequest);
  }, [instance]);

  return {
    b2cToken,
    loginB2C,
    loginB2CROPC,
    loginB2CSilent,
    logoutB2C,
    ropcError,
    setRopcError,
  };
};

export { useB2CInfo };
